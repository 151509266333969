/* eslint-disable */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id   : 'chat',
        title: 'Chat',
        type : 'basic',
        icon : 'heroicons_outline:academic-cap',
        link : '/chat'
    },
    {
        id   : 'history',
        title: 'Query History',
        type : 'basic',
        icon : 'heroicons_outline:queue-list',
        link : '/studio/history'
    },
    {
        id   : 'context',
        title: 'Contexts',
        type : 'basic',
        icon : 'heroicons_outline:circle-stack',
        link : '/context'
    },
    {
        id   : 'logout',
        title: 'Sign Out',
        type : 'basic',
        icon : 'heroicons_outline:arrow-left-on-rectangle',
        link : '/sign-out'
    },
];
export const compactNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'dwdwdww',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example'
    }
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'hola',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example'
    }
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id   : 'chat',
        title: 'Chat',
        type : 'basic',
        icon : 'heroicons_outline:academic-cap',
        link : '/chat'
    },
    
    {
        id   : 'context',
        title: 'Contexts',
        type : 'basic',
        icon : 'heroicons_outline:circle-stack',
        link : '/context'
    },
    {
        id   : 'logout',
        title: 'Sign Out',
        type : 'basic',
        icon : 'heroicons_outline:arrow-left-on-rectangle',
        link : '/sign-out'
    },
];
